<script setup lang="ts">
// NPM
import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { createI18nMessage, email, required } from "@vuelidate/validators";

// Components
import {
  BaseButton,
  FieldCheckbox,
  FieldInput,
} from "@magma-app/magma-lapilli";

// Helpers
import AnalyticsHelper from "@/helpers/analytics";
import { extractUtmOrCleanUrl } from "@/helpers/url";

// Composables & Stores
import { useConvertHexToRgb } from "@/composables/useConvertHexToRgb";
import { useAuthStore } from "@/stores/auth";
const { hexColor, rgbColor } = useConvertHexToRgb();
const authStore = useAuthStore();

// Plugins
const route = useRoute();
const { t } = useI18n();
const withI18nMessage = createI18nMessage({ t });

// Reactive variables
const formData = reactive({
  consent: undefined,
  email: "",
});
const root = ref<HTMLInputElement>();
const containerWidth = ref(0);
const widget = ref(false);
const isSubmitting = ref(false);

// Form validation
const v$ = useVuelidate(
  {
    consent: {
      required: withI18nMessage((value: boolean) => value === true, {
        messagePath: () => t("validation.value"),
      }),
    },
    email: {
      email: withI18nMessage(email, {
        messagePath: () => t("validation.invalidEmail"),
      }),
      required: withI18nMessage(required, {
        messagePath: () => t("validation.value"),
      }),
    },
  },
  formData
);

// Computed
const headerUrl = computed(() => {
  return authStore.helpeeSignUpData?.headerUrl
    ? `url(${authStore.helpeeSignUpData?.headerUrl})`
    : "url('/img/illustration_helpee_signup_lg.png')";
});

const helpersProfilePicture = computed(() => {
  const enoughHelpersPics =
    authStore.helpeeSignUpData?.helpersProfilePicture &&
    authStore.helpeeSignUpData?.helpersProfilePicture?.length > 6
      ? true
      : false;

  return [
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[1]
        : "/img/team/thibault.png",
      size: "h-10 w-10",
      margin: "!-mr-1",
      index: "z-[1]",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[2]
        : "/img/team/hugo.png",
      size: "h-14 w-14",
      margin: "!-mr-3",
      index: "z-[2]",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[3]
        : "/img/team/joris.png",
      size: "h-[72px] w-[72px]",
      margin: "!-mr-3",
      index: "z-[3]",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[0]
        : "/img/team/marine.png",
      size: "h-[88px] w-[88px]",
      margin: "!-mr-3",
      index: "z-[7]",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[4]
        : "/img/team/nada.png",
      size: "h-[72px] w-[72px]",
      margin: "!-ml-3",
      index: "z-[6]",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[5]
        : "/img/team/edouard.png",
      size: "h-14 w-14",
      margin: "!-ml-3",
      index: "z-[5]",
    },
    {
      picture: enoughHelpersPics
        ? authStore.helpeeSignUpData?.helpersProfilePicture?.[6]
        : "/img/team/tom.png",
      size: "h-10 w-10",
      margin: "!-ml-3",
      index: "z-[4]",
    },
  ];
});

const computeIntegrationStyles = () => {
  const hex = authStore.helpeeSignUpData?.primaryColor.replace("#", "") || "";
  const c_r = parseInt(hex.substring(0, 2), 16);
  const c_g = parseInt(hex.substring(2, 4), 16);
  const c_b = parseInt(hex.substring(4, 6), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  const brandColor = brightness > 155 ? "#1C1F27" : "#FFFFFF";
  return brandColor;
};

const adjustColorBrightness = (hex: string, factor: number) => {
  const c_r = parseInt(hex.substring(0, 2), 16);
  const c_g = parseInt(hex.substring(2, 4), 16);
  const c_b = parseInt(hex.substring(4, 6), 16);

  const adjust = (color: number) =>
    Math.max(0, Math.min(255, Math.floor(color * factor)));

  const new_r = adjust(c_r);
  const new_g = adjust(c_g);
  const new_b = adjust(c_b);

  return `#${new_r.toString(16).padStart(2, "0")}${new_g
    .toString(16)
    .padStart(2, "0")}${new_b.toString(16).padStart(2, "0")}`;
};

const computeIntegrationBrandColor = computed(() => {
  const hex = authStore.helpeeSignUpData?.primaryColor.replace("#", "") || "";
  const c_r = parseInt(hex.substring(0, 2), 16);
  const c_g = parseInt(hex.substring(2, 4), 16);
  const c_b = parseInt(hex.substring(4, 6), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

  // Si la luminosité est supérieure à 155, la couleur est assombrie de 20%, sinon la couleur de base est renvoyée
  const factor = brightness > 155 ? 0.8 : 1; // 0.8 pour assombrir de 20%
  return brightness > 155 ? adjustColorBrightness(hex, factor) : `#${hex}`;
});

// Event emitters
const emit = defineEmits(["success"]);

// Watchers
watch(
  () => formData.consent,
  async () => await v$.value.consent.$validate()
);

// Lifecycle hooks
onMounted(async () => {
  if (route?.query.widget) {
    widget.value = true;
  }

  computeContainerWidth();

  window.addEventListener("resize", computeContainerWidth);

  if (authStore.helpeeSignUpData?.primaryColor) {
    hexColor.value = authStore.helpeeSignUpData.primaryColor;
  }

  // Track load page event
  AnalyticsHelper.trackEvent("user_start_signup");

  // Add mail to the field with params
  const params = route.query;

  if (params?.email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(params.email as string)) {
      formData.email = params.email as string;

      v$.value.email.$touch();
    }
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", computeContainerWidth);
});

// Methods
const onValidateEmail = async () => {
  await v$.value.email.$validate();
};

// Methods
const submitForm = async () => {
  isSubmitting.value = true;

  try {
    v$.value.$touch();

    if (await v$.value.$validate()) {
      v$.value.$reset();

      const source =
        (route?.query.source as string) ||
        (route?.query.utm_source as string) ||
        (route?.query.utm_medium as string) ||
        document.referrer ||
        "";

      const payload = {
        email: formData.email,
        organizationId: authStore.helpeeSignUpData?.organizationId,
        campaignUuid: route?.params.campaignUuid as string,
        source: extractUtmOrCleanUrl(source),
      };

      const data = await authStore.signUpHelpee(payload);

      emit("success", data);

      // Track ask for match event
      AnalyticsHelper.trackEvent("user_ask_match");
    }
  } catch (error) {
    console.error(error);
  }

  isSubmitting.value = false;
};

const computeContainerWidth = () => {
  containerWidth.value = root.value?.offsetWidth || 0;
};

onMounted(() => {
  const source =
    (route?.query.source as string) ||
    (route?.query.utm_source as string) ||
    (route?.query.utm_medium as string) ||
    document.referrer ||
    "";

  const test = extractUtmOrCleanUrl(source);
  console.log(test);
});
</script>

<template>
  <div
    ref="root"
    :class="[
      'min-h-[200px]',
      {
        'bg-cover bg-center':
          authStore.helpeeSignUpData?.headerType === 'image',
        'flex flex-col items-center px-6 pt-6':
          authStore.helpeeSignUpData?.headerType === 'community',
        'md:rounded-t-[15px]': widget && containerWidth >= 600,
      },
    ]"
    :style="{
      backgroundImage:
        authStore.helpeeSignUpData?.headerType === 'image'
          ? headerUrl
          : undefined,
    }"
  >
    <template v-if="authStore.helpeeSignUpData?.headerType === 'community'">
      <div class="flex flex-shrink-0 items-center -space-x-2">
        <div
          v-for="(helper, helperIndex) in helpersProfilePicture"
          :key="helperIndex"
          :class="[helper.index, helper.margin]"
        >
          <div
            :class="[
              'overflow-hidden rounded-full',
              helper.size,
              {
                'border-2 border-border-on-emphasis':
                  authStore.helpeeSignUpData?.helpersProfilePicture &&
                  authStore.helpeeSignUpData?.helpersProfilePicture?.length > 6,
              },
            ]"
          >
            <img class="h-full w-full object-cover" :src="helper.picture" />
          </div>
        </div>
      </div>

      <div
        class="z-[8] -mt-3.5 flex max-w-[85%] items-start rounded-2xl border border-border-subtle bg-background-default p-3.5 shadow-[0px_2.63084px_3.94626px_-1.31542px_rgba(28,31,39,0.03),0px_7.89252px_10.52336px_-2.63084px_rgba(28,31,39,0.08)]"
      >
        <div class="relative">
          <div
            :class="[
              'h-[38px] w-[38px] overflow-hidden rounded-full',
              {
                'border-2 border-border-on-emphasis':
                  authStore.helpeeSignUpData?.helpersProfilePicture &&
                  authStore.helpeeSignUpData?.helpersProfilePicture?.length > 6,
              },
            ]"
          >
            <img
              class="h-full w-full object-cover"
              :src="
                authStore.helpeeSignUpData?.helpersProfilePicture &&
                authStore.helpeeSignUpData?.helpersProfilePicture?.length > 6
                  ? authStore.helpeeSignUpData?.helperHighlighted
                      ?.profilePicture
                  : '/img/team/marine.png'
              "
            />
          </div>

          <div
            class="absolute bottom-0 right-0 h-[15px] w-[15px] overflow-hidden"
          >
            <img
              class="h-full w-full object-cover"
              src="/img/logos/instagram.svg"
            />
          </div>
        </div>

        <div class="ml-3.5 text-mgm-txt-xs">
          <div class="flex items-center justify-between">
            <span class="block font-semibold">
              {{
                (authStore.helpeeSignUpData?.helpersProfilePicture &&
                authStore.helpeeSignUpData?.helpersProfilePicture?.length > 6
                  ? authStore.helpeeSignUpData?.helperHighlighted?.firstname
                  : "Marine") +
                " " +
                (authStore.helpeeSignUpData?.helpersProfilePicture &&
                authStore.helpeeSignUpData?.helpersProfilePicture?.length > 6
                  ? authStore.helpeeSignUpData?.helperHighlighted?.lastname?.charAt(
                      0
                    )
                  : "B.") +
                "."
              }}
            </span>

            <span class="block font-normal text-foreground-subtle"> 9:41 </span>
          </div>

          <span class="block font-normal text-foreground-default">
            {{ $t("helpeeSignUp.creation.header.community.msg") }}
          </span>
        </div>
      </div>
    </template>
  </div>

  <div
    data-cy="signupWelcome"
    :class="[
      widget
        ? containerWidth < 600
          ? 'px-6 pb-6 pt-10'
          : `p-10 ${
              authStore.helpeeSignUpData?.headerType === 'community'
                ? 'pt-4'
                : ''
            }`
        : `px-6 pb-6 pt-10 md:p-10 ${
            authStore.helpeeSignUpData?.headerType === 'community'
              ? 'md:pt-4'
              : 'md:pt-3'
          } `,
    ]"
  >
    <h2 class="text-mgm-dspl-xs font-semibold">
      <span class="block text-foreground-default">
        {{ $t("helpeeSignUp.creation.header.community.title") }}
      </span>
      <span class="block text-foreground-emphasis">
        {{ $t("helpeeSignUp.creation.header.community.subtitle") }}
        {{ authStore.helpeeSignUpData?.helperNaming.toLowerCase() }}
      </span>
    </h2>

    <FieldInput
      id="email"
      v-model="formData.email"
      data-cy="email"
      class="mt-6"
      role="email"
      type="email"
      :locale="$i18n.locale"
      :label="$t('generic.yourEmail')"
      :placeholder="`${$t('generic.example')}@email.com`"
      autocomplete="email"
      required
      :validation="v$.email"
      :custom-color="authStore.helpeeSignUpData?.primaryColor"
      :custom-rgb-color="
        rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
      "
      @blur="onValidateEmail"
    />

    <FieldCheckbox
      id="consent"
      v-model="formData.consent"
      data-cy="consent"
      class="mt-6"
      :custom-color="computeIntegrationBrandColor"
      :custom-rgb-color="
        rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
      "
      :validation="v$.consent"
      required
    >
      <span class="text-mgm-txt-sm font-normal text-foreground-default">
        {{ $t("generic.agree") }}

        <a
          class="font-medium"
          :class="{
            'text-foreground-brand-default':
              !authStore.helpeeSignUpData?.primaryColor,
          }"
          :style="{
            color:
              computeIntegrationStyles() === '#1C1F27'
                ? '#1C1F27'
                : authStore.helpeeSignUpData?.primaryColor || undefined,
          }"
          href="https://fr.magma.app/legals/supported-terms-of-use"
          target="_blank"
        >
          {{ $t("generic.termsAndPrivacy.part2") }}
        </a>

        {{ $t("generic.termsAndPrivacy.part3") }}

        <a
          class="font-medium"
          :class="{
            'text-foreground-brand-default':
              !authStore.helpeeSignUpData?.primaryColor,
          }"
          :style="{
            color:
              computeIntegrationStyles() === '#1C1F27'
                ? '#1C1F27'
                : authStore.helpeeSignUpData?.primaryColor || undefined,
          }"
          :href="authStore.helpeeSignUpData?.termsUrl"
          target="_blank"
        >
          {{ $t("generic.termsAndPrivacy.part4") }}
        </a>
      </span>
    </FieldCheckbox>
    <BaseButton
      data-cy="signup-start"
      :class="[
        widget ? (containerWidth < 600 ? 'mb-12 mt-8' : 'mt-8') : 'my-8',
      ]"
      class="mx-auto w-full"
      :custom-color="authStore.helpeeSignUpData?.primaryColor"
      :custom-rgb-color="
        rgbColor ? rgbColor : authStore.helpeeSignUpData?.primaryColor
      "
      :disabled="
        !v$.email.$dirty ||
        v$.email.$error ||
        !v$.consent.$dirty ||
        v$.consent.$error ||
        isSubmitting
      "
      size="md"
      @click="submitForm"
      ><span :style="{ color: computeIntegrationStyles() }">{{
        authStore.helpeeSignUpData?.widgetTitle
      }}</span></BaseButton
    >
  </div>
</template>
