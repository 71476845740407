// NPM
import { createApp } from "vue";
import { createPinia } from "pinia";
import { Vue3Mq } from "vue3-mq";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { VueQueryPlugin } from "@tanstack/vue-query";

// Plugins
import i18n from "@/plugins/i18n";
import FloatingVue from "floating-vue";
// Vue
import App from "@/App.vue";
import router from "@/router";

// Stylesheets
// Order matters (overrides lapilli styles with codebase styles)
import "@magma-app/magma-lapilli/dist/style.css";
import "@/assets/base.css";
import "@/assets/floating-vue.css";
import MasonryWall from "@yeger/vue-masonry-wall";

// Initialize Vue App
const app = createApp(App);

// initialize pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Set up plugins
app.use(pinia);
app.use(i18n);
app.use(Vue3Mq, {
  preset: "tailwind",
  breakpoints: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1440,
    xxl: 1536,
  },
});
app.use(FloatingVue);
app.use(router);
app.use(VueQueryPlugin);
app.use(MasonryWall);

// Attach Vue instance to the DOM
router.isReady().then(() => {
  app.mount("#app");
});
